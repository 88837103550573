import React, { useEffect, useState } from "react";
import Amplify, {Auth, Hub} from "aws-amplify";
import LoginButton from "./LoginButton";
import Main from "./Main";
import "./index.css";
import { config } from "./config/config";

// const oauth = {
//     domain: "tescomobile.auth.eu-west-2.amazoncognito.com", // ref
//     scope: ["email"],
//     redirectSignIn: "https://tlxmobilereturnsref.co.uk", // ref
//     redirectSignOut: "",
//     responseType: "token"
// };

// Amplify.configure({
//     region: "eu-west-2",
//     userPoolId: "eu-west-2_ZSxaB9GSL", // ref
//     userPoolWebClientId: "43b1588vgpl1d567tfn3jgnf7c", // ref
//     mandatorySignIn: true
// });

// const oauth = {
//     domain: "tescomobilereturns.auth.eu-west-2.amazoncognito.com", // live
//     scope: ["email"],
//     redirectSignIn: "https://tlxmobilereturns.co.uk", // live
//     redirectSignOut: "",
//     responseType: "token"
// };

// Amplify.configure({
//     region: "eu-west-2",
//     userPoolId: "eu-west-2_AKpAFFGuK", // live
//     userPoolWebClientId: "100o2eu1o2cg1thqaci27i6qrn", // live
//     mandatorySignIn: true
// });

const oauth = {
    domain: config.auth.domain,
    scope: ["email"],
    redirectSignIn: config.auth.redirectURL,
    redirectSignOut: "",
    responseType: "token"
};

Amplify.configure({
    region: "eu-west-2",
    userPoolId: config.auth.userpoolID,
    userPoolWebClientId: config.auth.clientID,
    mandatorySignIn: true
});

Auth.configure({ oauth });

const App = props => {
    const [authState, setAuthState] = useState("loading");
    const [authData, setAuthData] = useState(null);
    const [authError, setAuthError] = useState(null);

    Hub.listen("auth", data => {
        switch (data.payload.event) {
            case "signIn":
                setAuthState("signedIn");
                setAuthData(data.payload.data);
                break;
            case "signIn_failure":
                setAuthState("signIn");
                setAuthData(null);
                setAuthError(data.payload.data);
                break;
            default:
                break;
        }
    })

    useEffect(() => {
        Auth.currentAuthenticatedUser()
        .then(user => {
            setAuthState("signedIn");
        })
        .catch(e => {
            setAuthState("signIn")
        });
    }, []);

    const signOut = () => {
        Auth.signOut()
        .then(() => {
            setAuthState("signIn")
        })
        .catch(e => {
            console.log(e)
        });

    };

    if (authState === "signedIn") {
    return <Main />;
    } else if (authState === "loading") {
        return <div>Loading...</div>;
    } else {
        return <LoginButton />;
    }
};

export default App;