import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Auth } from 'aws-amplify';

import {
  TextField, MenuItem, Paper, Table, TableHead,
  TableRow, TableCell, TableBody} from '@material-ui/core';

import Api from '../service/api';


const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(3, 2),
      margin: theme.spacing(3, 3, 3, 3),
      height: '60vh',
    },
    button: {
      marginRight: theme.spacing(1),
      float: "right"
    },
    table: {
      minWidth: 650,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginBottom: 16,
      marginTop: 0,
      width: 300,
    },
  }));

export default function Collection(props) {
  const classes = useStyles();

  const [dropDowns, setDropDowns] = React.useState({
    stores: [],
  });
  const [values, setValues] = React.useState({
    Boxes: [],
  });
  const [stores, setStores] = React.useState({
    store: '',
    email: ''
  });

  useEffect(() => {
    Api.getStores(Auth.currentSession()).then((response) => {
        const storeList = response.data;
        setDropDowns({ ...dropDowns, stores: storeList.sort() })
    })
    .catch((error) => {
        alert('Cannot retreive Store IDs, contact an administrator')
    })

    // var email;
    // props.auth.currentUserInfo().then((userInfo) => {
    //   const { attributes = {} } = userInfo;
    //   email = attributes['email'];
    //   setStores({ ...stores, email: attributes['email']})
    //   Api.getStoreFromUsername(email).then((response) => {
    //     setStores({ ...stores, store: response.data })
    //     if (response.data.length !== 0) {
    //       this.getBoxes(response.data);
    //     }
    //   })
    // })

    // eslint-disable-next-line    
  }, [])

  const handleChange = name => event => {
    setStores({ ...stores, [name]: event.target.value });
    getBoxes(event.target.value);
  };

  const getBoxes = (store) => {
    Api.getStoreCollectionDetails(Auth.currentSession(), store).then((response) => {
      const boxesList = response.data;
      setValues({ ...values, Boxes: boxesList })
    })
    .catch((error) => {
      alert('Cannot retreive collection information')
    })
  }

  return (
    <div className={classes.root}>
        <Paper className={classes.root} elevation={12}>
            <TextField
              select
              label="Select Store ID"
              variant="outlined"
              value={stores.store}
              className={classes.textField}
              onChange={handleChange('store')}
              name="store"
              inputProps={{
                name: 'store',
                id: 'store',
              }}
              helperText="Select Store ID"
            >
          {dropDowns.stores.map(item => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
            ))
          }
            </TextField>
        
          <Table className={classes.table}>
            <TableHead>
              <TableRow hover>
                <TableCell>Tote Number</TableCell>
                <TableCell>Tote Secondary Number</TableCell>
                <TableCell>IMEI</TableCell>
                <TableCell>Booking Date</TableCell>
                <TableCell>Manufacturer</TableCell>
                <TableCell>Model</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {values.Boxes.map(row => (
                <TableRow key={row.name}>
                  <TableCell key={row.ToteNum}>{row.ToteNum}</TableCell>
                  <TableCell key={row.ToteSecondary}>{row.ToteSecondary}</TableCell>
                  <TableCell key={row.IMEI}>{row.IMEI}</TableCell>
                  <TableCell key={row.BookingDate}>{row.BookingDate}</TableCell>
                  <TableCell key={row.Manufacturer}>{row.Manufacturer}</TableCell>
                  <TableCell key={row.Model}>{row.Model}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
    </div>
  );
}