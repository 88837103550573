import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import TescoLogo from '../assets/TES_MOB_Primary_Logo_RedBlue_RGB.svg';
import CeLogo from '../assets/tlxlogo.png';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  }
});

export default function AppBarComponent() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar style={{backgroundColor: 'white'}} position="static">
        <Toolbar>
          <Typography variant="h6" color="inherit">
            <img src={TescoLogo} alt='Tesco Mobile Logo' className='tescoLogo' />
            <img src={CeLogo} alt='Cheapest Electrical Logo' className='ceLogo' />
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
}