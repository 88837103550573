import axios from 'axios';
import { config } from '../config/config';

const baseUrl = config.apiURL

function getImei(currentSession, imei, EAN) {
    return new Promise((resolve, reject) => {
        currentSession.then(session => {
            const headers = {
                'Accept': 'application/json',
                'content-type': 'application/json',
                Authorization: session.idToken.jwtToken
            };
            axios.get(baseUrl + '/api/lookup/tac?IMEI=' + imei + '&EAN=' + EAN, {headers: headers})
            .then(resp => {
                resolve(resp)
            })
            .catch(err => {
                reject(err);
            })
        })
    })
} 

function getPrimaryFaultCodes(currentSession) {
    return new Promise((resolve, reject) => {
        currentSession.then(session => {
            const headers = {
                'Accept': 'application/json',
                'content-type': 'application/json',
                Authorization: session.idToken.jwtToken
            };
            axios.get(baseUrl + '/api/unit/fault', {headers: headers})
            .then(resp => {
                resolve(resp)
            })
            .catch(err => {
                reject(err);
            })
        })
    })
}

function getSecondaryFaultCodes(currentSession, primary) {
    return new Promise((resolve, reject) => {
        currentSession.then(session => {
            const headers = {
                'Accept': 'application/json',
                'content-type': 'application/json',
                Authorization: session.idToken.jwtToken
            };
            axios.get(baseUrl + '/api/unit?primary=' + primary, {headers: headers})
            .then(resp => {
                resolve(resp)
            })
            .catch(err => {
                reject(err);
            })
        })
    })
}

function getGSXLookup(currentSession, imei) {
    return new Promise((resolve, reject) => {
        currentSession.then(session => {
            const headers = {
                'Accept': 'application/json',
                'content-type': 'application/json',
                Authorization: session.idToken.jwtToken
            };
            axios.get(baseUrl + '/api/lookup/gsx?IMEI=' + imei, {headers: headers})
            .then(resp => {
                resolve(resp)
            })
            .catch(err => {
                reject(err);
            })
        })
    })
}

function postUnit(currentSession, data) {
    return new Promise((resolve, reject) => {
        currentSession.then(session => {
            const headers = {
                'Accept': 'application/json',
                'content-type': 'application/json',
                Authorization: session.idToken.jwtToken
            };
            axios.post(baseUrl + '/api/unit', data, { headers })
            .then(resp => {
                resolve(resp)
            })
            .catch(err => {
                reject(err);
            })
        })
    })
}

function getStores(currentSession) {
    return new Promise((resolve, reject) => {
        currentSession.then(session => {
            const headers = {
                'Accept': 'application/json',
                'content-type': 'application/json',
                Authorization: session.idToken.jwtToken
            };
            axios.get(baseUrl + '/api/store', {headers: headers})
            .then(resp => {
                resolve(resp)
            })
            .catch(err => {
                reject(err);
            })
        })
    })
}

function getManufactures(currentSession) {
    return new Promise((resolve, reject) => {
        currentSession.then(session => {
            const headers = {
                'Accept': 'application/json',
                'content-type': 'application/json',
                Authorization: session.idToken.jwtToken
            };
            axios.get(baseUrl + '/api/ReferenceData', {headers: headers})
            .then(resp => {
                resolve(resp)
            })
            .catch(err => {
                reject(err);
            })
        })
    })
}

function getModel(currentSession, model) {
    return new Promise((resolve, reject) => {
        currentSession.then(session => {
            const headers = {
                'Accept': 'application/json',
                'content-type': 'application/json',
                Authorization: session.idToken.jwtToken
            };
            axios.get(baseUrl + '/api/ReferenceData?manufacturer=' + model, {headers: headers})
            .then(resp => {
                resolve(resp)
            })
            .catch(err => {
                reject(err);
            })
        })
    })
}

function getStoreBoxes(currentSession,store) {
    return new Promise((resolve, reject) => {
        currentSession.then(session => {
            const headers = {
                'Accept': 'application/json',
                'content-type': 'application/json',
                Authorization: session.idToken.jwtToken
            };
            axios.get(baseUrl + '/api/Boxes?storeID=' + store, {headers: headers})
            .then(resp => {
                resolve(resp)
            })
            .catch(err => {
                reject(err);
            })
        })
    })
}

function getStoreBoxDetails(currentSession, store) {
    return new Promise((resolve, reject) => {
        currentSession.then(session => {
            const headers = {
                'Accept': 'application/json',
                'content-type': 'application/json',
                Authorization: session.idToken.jwtToken
            };
            axios.get(baseUrl + '/api/Unit?storeID=' + store, {headers: headers})
            .then(resp => {
                resolve(resp)
            })
            .catch(err => {
                reject(err);
            })
        })
    })
}

function getStoreCollectionDetails(currentSession, store) {
    return new Promise((resolve, reject) => {
        currentSession.then(session => {
            const headers = {
                'Accept': 'application/json',
                'content-type': 'application/json',
                Authorization: session.idToken.jwtToken
            };
            axios.get(baseUrl + '/api/store/collectioninfo?storeID=' + store, {headers: headers})
            .then(resp => {
                resolve(resp)
            })
            .catch(err => {
                reject(err);
            })
        })
    })
}
function getStoreFromUsername(currentSession, username) {
    return new Promise((resolve, reject) => {
        currentSession.then(session => {
            const headers = {
                'Accept': 'application/json',
                'content-type': 'application/json',
                Authorization: session.idToken.jwtToken
            };
            axios.get(baseUrl + '/api/Store?username=' + username, {headers: headers})
            .then(resp => {
                resolve(resp)
            })
            .catch(err => {
                reject(err);
            })
        })
    })
}

function unitLocked(currentSession, imei) {
    return new Promise((resolve, reject) => {
        currentSession.then(session => {
            const headers = {
                'Accept': 'application/json',
                'content-type': 'application/json',
                Authorization: session.idToken.jwtToken
            };
            axios.get(baseUrl + '/api/Lock?imei='+ imei, { headers: headers })
            .then(resp => {
                resolve(resp)
            })
            .catch(err => {
                reject(err);
            })
        })
    })
}

function getFactoryResetReasons(currentSession) {
    return new Promise((resolve, reject) => {
        currentSession.then(session => {
            const headers = {
                'Accept': 'application/json',
                'content-type': 'application/json',
                Authorization: session.idToken.jwtToken
            };
            axios.get(baseUrl + '/api/factoryresetreasons', { headers: headers })
            .then(resp => {
                resolve(resp)
            })
            .catch(err => {
                reject(err);
            })
        })
    })
}

// Accessory endpoints

function getModelAcc(currentSession) {
    return new Promise((resolve, reject) => {
        currentSession.then(session => {
            const headers = {
                'Accept': 'application/json',
                'content-type': 'application/json',
                Authorization: session.idToken.jwtToken
            };
            axios.get(baseUrl + '/api/AccessoryLookup/accessory', {headers: headers})
            .then(resp => {
                resolve(resp)
            })
            .catch(err => {
                reject(err);
            })
        })
    })
}

function getPrimaryFaultCodesAcc(currentSession) {
    return new Promise((resolve, reject) => {
        currentSession.then(session => {
            const headers = {
                'Accept': 'application/json',
                'content-type': 'application/json',
                Authorization: session.idToken.jwtToken
            };
            axios.get(baseUrl + '/api/Accessory/fault', {headers: headers})
            .then(resp => {
                resolve(resp)
            })
            .catch(err => {
                reject(err);
            })
        })
    })
}

function getSecondaryFaultCodesAcc(currentSession, primary) {
    return new Promise((resolve, reject) => {
        currentSession.then(session => {
            const headers = {
                'Accept': 'application/json',
                'content-type': 'application/json',
                Authorization: session.idToken.jwtToken
            };
            axios.get(baseUrl + '/api/accessory/fault?primary=' + primary, {headers: headers})
            .then(resp => {
                resolve(resp)
            })
            .catch(err => {
                reject(err);
            })
        })
    })
}

function postUnitAcc(currentSession, data) {
    return new Promise((resolve, reject) => {
        currentSession.then(session => {
            const headers = {
                'Accept': 'application/json',
                'content-type': 'application/json',
                Authorization: session.idToken.jwtToken
            };
            axios.post(baseUrl + '/api/accessory', data, { headers })
            .then(resp => {
                resolve(resp)
            })
            .catch(err => {
                reject(err);
            })
        })
    })
}

function getMceReasons(currentSession) {
    return new Promise((resolve, reject) => {
        currentSession.then(session => {
            const headers = {
                'Accept': 'application/json',
                'content-type': 'application/json',
                Authorization: session.idToken.jwtToken
            };
            axios.get(baseUrl + '/api/lookup/mce', { headers })
            .then(resp => {
                resolve(resp)
            })
            .catch(err => {
                reject(err);
            })
        })
    })
}

export default {
    getImei,
    getPrimaryFaultCodes,
    getSecondaryFaultCodes,
    getGSXLookup,
    postUnit,
    getStores,
    getManufactures,
    getModel,
    getStoreBoxes,
    getStoreBoxDetails,
    getStoreFromUsername,
    unitLocked,
    getFactoryResetReasons,
    getStoreCollectionDetails,
    getModelAcc,
    getPrimaryFaultCodesAcc,
    getSecondaryFaultCodesAcc,
    postUnitAcc,
    getMceReasons
}