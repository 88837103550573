import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    TextField, MenuItem, Grid
} from '@material-ui/core';
import { Auth } from 'aws-amplify';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsAdapter from "@date-io/date-fns"

import Api from '../service/api';
import handleErrors from '../service/helpers/errorResponse';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3, 2),
        margin: theme.spacing(3, 3, 3, 3),
        height: '80vh',
    },
    container: {
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: 16,
        marginTop: 5,
        width: 300,
    },
    dense: {
        marginTop: 19,
    },
    menu: {
        width: 200,
    },
    button: {
        margin: theme.spacing(1),
        maxHeight: 50
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    formControl: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 300,
    },
}));

export default function BookAccessory(props) {
    const classes = useStyles();

    const [models, setModels] = React.useState([{Description: '', TPNB: '', Unattach: 0}]);
    const [primaryFaults, setPrimaryFaults] = React.useState();
    const [secondaryFaults, setSecondaryFaults] = React.useState();
    const date = new Date();

    useEffect(() => {
        Api.getPrimaryFaultCodesAcc(Auth.currentSession()).then((response) => {
            setPrimaryFaults(response.data)
        }).catch((error) => {
            alert('Cannot retreive Primary fault codes, contact an administrator')
        })

        Api.getModelAcc(Auth.currentSession()).then((response) => {
            setModels(response.data)
        }).catch((error) => {
            alert('Cannot retreive the list of Models, contact an administrator')
        })

        // eslint-disable-next-line
    }, [])

    const handleChange = name => event => {
        props.onChange(name, event);
        if (name === 'primaryFaultCodeAcc') {
            getSecondary(event.target.value);
        }
    };

    function handleDateChange(date) {
        props.date('SaleDateAcc', date);
    }

    function getSecondary(secondary) {
        Api.getSecondaryFaultCodesAcc(Auth.currentSession(), secondary).then((response) => {
            setSecondaryFaults(response.data)
        })
            .catch((error) => {
                handleErrors(error)
            })
    }

    return (
        <Grid container spacing={2} style={{ height: '100%', overflowY: 'auto' }}>
            <form className={classes.container} noValidate autoComplete="off">
                <Grid item xs={8}>
                    <TextField
                        select
                        label="Select Model"
                        value={props.values.ModelAcc}
                        className={classes.textField}
                        variant="outlined"
                        required
                        onChange={handleChange('ModelAcc')}
                        name="Model"
                        inputProps={{
                            name: 'Model',
                            id: 'Model',
                        }}
                    >
                        {models && models.length > 0 ? models.map(item => (
                            <MenuItem key={item} value={item}>
                                {item.Description}
                            </MenuItem>
                        )) : <MenuItem key={''} value={''}></MenuItem>
                        }
                    </TextField>
                </Grid>
                <br />
                <Grid item xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsAdapter}>
                    <KeyboardDatePicker
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        label="Sale date"
                        disableFuture
                        minDate={date.setDate(date.getDate() - 30)}
                        format="dd/MM/yyyy"
                        maxDateMessage={'Sale date can not be in the future'}
                        minDateMessage={'Sale date can not be more than 30 days ago'}
                        onError={(error, value ) => error ? props.validDate(false) : props.validDate(true)}
                        className={classes.textField}
                        value={props.values.SaleDateAcc}
                        onChange={date => handleDateChange(date)}
                    />
                    </MuiPickersUtilsProvider>
                    <TextField
                        id="ToteNum"
                        label="Seal 1:"
                        required
                        helperText="Enter Seal 1 Number"
                        onChange={handleChange('ToteNum')}
                        value={props.values.ToteNum}
                        className={classes.textField}
                        margin="normal"
                        variant="outlined"
                    />
                    <TextField
                        id="other tote field"
                        label="Seal 2:"
                        required
                        helperText="Enter Seal 2 Field"
                        onChange={handleChange('OtherTote')}
                        value={props.values.OtherTote}
                        className={classes.textField}
                        margin="normal"
                        variant="outlined"
                    />
                    <TextField
                        select
                        label="Primary Fault Code"
                        variant="outlined"
                        required
                        value={props.values.primaryFaultCodeAcc}
                        className={classes.textField}
                        onChange={handleChange('primaryFaultCodeAcc')}
                        name="PrimaryFaultCodeAcc"
                        inputProps={{
                            name: 'PrimaryFaultCodeAcc',
                            id: 'PrimaryFaultCodeAcc',
                        }}
                    >
                        {primaryFaults && primaryFaults.length > 0 ? primaryFaults.map(item => (
                            <MenuItem key={item.Primary} value={item.Primary}>
                                {item.Primary}
                            </MenuItem>
                        )): <MenuItem key={''} value={''}></MenuItem>
                        }
                    </TextField>
                    <TextField
                        select
                        label="Secondary Fault Code"
                        variant="outlined"
                        required
                        value={props.values.SecondaryFaultCodeAcc}
                        className={classes.textField}
                        onChange={handleChange('SecondaryFaultCodeAcc')}
                        name="SecondaryFaultCodeAcc"
                        inputProps={{
                            name: 'SecondaryFaultCodeAcc',
                            id: 'SecondaryFaultCodeAcc',
                        }}
                    >
                        {secondaryFaults && secondaryFaults.length > 0 ? secondaryFaults.map(item => (
                            <MenuItem key={item.Secondary} value={item.Secondary}>
                                {item.Secondary}
                            </MenuItem>
                        )): <MenuItem key={''} value={''}></MenuItem>
                        }
                    </TextField>
                    <TextField
                        id="freetextAcc"
                        label="Additional information"
                        multiline
                        rows="4"
                        rowsMax="10"
                        value={props.values.FreeTextAcc}
                        onChange={handleChange('FreeTextAcc')}
                        className={classes.textField}
                        variant="outlined"
                    />
                </Grid>
            </form>
        </Grid>
    )
}