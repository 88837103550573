import React, { Component } from 'react';

import AppBarComponent from './components/AppBar'
import { AppBar, Toolbar } from '@material-ui/core';

import './App.css';
import TescoStepper from './containers/TescoStepper';
import Booked from './containers/Booked';
import Amplify, { Auth } from 'aws-amplify';
import { withAuthenticator } from 'aws-amplify-react';
import { polyfillLoader } from 'polyfill-io-feature-detection';

import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom'
import Collection from './containers/Collection';

polyfillLoader({
  "features": "Promise,fetch",
  "onCompleted": Main
});


class Main extends Component {

  render() {
    return (
      <div className="App">
        <div>
          <Router>
          <div className="banner">
            <p>Do not enter any customer details within this application</p>
          </div>
          <AppBarComponent />
          
            <AppBar position="static">
              <Toolbar variant='regular'>
                <Link to="/" className="link">Home</Link>
                <Link to="/booked" className="link">Booked</Link>
                <Link to="/collection" className="link">Collections</Link>
              </Toolbar>
            </AppBar>
            <Switch>
              <Route exact path="/">
                <TescoStepper auth={Auth} />
              </Route>
              <Route path="/booked">
                <Booked auth={Auth}/>
              </Route>
              <Route path="/collection">
                <Collection auth={Auth}/>
              </Route>
            </Switch>
          </Router>
        </div>
      </div>
    );
  }
}

export default withAuthenticator(Main);
