import { withOAuth } from "aws-amplify-react";
import React, { Component } from "react";

class LoginButton extends Component {
    render() {
        this.props.OAuthSignIn();
        return <div />;
    }
}

export default withOAuth(LoginButton);