import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormGroup, FormControlLabel, Checkbox,
  Grid, MenuItem, TextField
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
    margin: theme.spacing(3, 3, 3, 3),
    height: '80vh',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: 16,
    marginTop: 0,
    width: 250,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
    maxHeight: 50
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  }
}));
export default function ReturnPolicy(props) {
  const classes = useStyles();

  const handleCheckboxChange = () => event => {
    props.onCheckboxChange(event);
  };

  const handleFactoryResetCheckboxChange = () => event => {
    props.onFactoryResetCheckboxChange(event);
  }

  const handleChange = name => event => {
    props.onChange(name, event);
    if (name === 'store') {
      props.getStoreBox(event.target.value);
    }
  };

  return (
    <Grid container spacing={2} style={{ height: '100%', overflowY: 'hidden' }}>
      <Grid item xs={8} style={{ height: 'inherit', overflowY: 'auto' }}>
        <p className="pageTitles" style={{ marginTop: 0, marginBottom: 20 }}>
          TESCO MOBILE RETURNS PROCESS
        </p>
        <p
          className="pageSubTitles"
          style={{ marginTop: 0, marginBottom: 40 }}
        >
          Tesco return date policy
        </p>

        <p
          className="headings"
        >
          Accessory
        </p>

        <ul style={{ listStyle: "none" }}>
          <li className="bodyText" >If you would like to book an accessory please select the "Book Accessory" booking type</li>
        </ul>

        <ul style={{ listStyle: "none" }}>
          <li className="bodyText" >Change of Mind – 30 days from purchase Manufacturer Faulty</li>
          <br />
          <li className="bodyText" >Manufacturer Faulty – 30 days from purchase</li>
        </ul>

        <p
          className="headings"
        >
          Pay As You Go
        </p>

        <ul style={{ listStyle: "none" }}>
          <li className="bodyText" >Change of Mind – 30 days from purchase Manufacturer Faulty</li>
          <br />
          <li className="bodyText" >Manufacturer Faulty – 30 days from purchase</li>
        </ul>

        <p
          className="headings"
        >
          Pay Monthly
        </p>

        <ul style={{ listStyle: "none" }}>
          <li className="bodyText" >Change of Mind – 14 days from purchase</li>
          <br />
          <li className="bodyText" >Manufacturer Faulty – 30 days from purchase</li>
        </ul>

        <p
          className="headings"
        >
          Return Criteria
        </p>

        <ul>
          <li>
            <p className="bodyText" >
              <b>Within return policy</b> by sales and return date (as above)
            </p>
          </li>
          <li>
            <p className="bodyText" >
              <b>As sold condition.</b> Including original box and any
              accessories
            </p>
          </li>
          <li>
            <p className="bodyText" >
              <b>No physical damage.</b> Devices should not be returned if it
              has any signs of physical damage (e.g. smashed screen). This
              includes water damages.
            </p>
          </li>
          <li>
            <p className="bodyText" >
              <b>All Android, FMI and PIN locks must be removed</b>
            </p>
          </li>
          <li>
            <p className="bodyText" >
              <b>Please make sure all sims and e-sim data is removed from the device where applicable.</b>
            </p>
          </li>
          <li>
            <p className="bodyText" >
              <b style={{ color: "red" }}>Factory Reset.</b> All customer data
              on the device must be removed before the device is returned.
              Please refer to the factory reset process in e-learning for any
              additional support required. Device help link - <a href="https://tmservice.my.salesforce.com/">
                https://support.tescomobile.com/customer/picker.html
              </a>
            </p>
            {/* {props.values.bookingType !== "Book Accessory" ?
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox color="primary" checked={props.values.factoryReset} disabled={props.values.bookingType === ''} onChange={handleFactoryResetCheckboxChange()} />
                }
                className="bodyText"
                label="Tick this box if you cannot factory reset the device"
              />
            {props.values.factoryReset ?
              <>
                <TextField
                  label="Select Reason"
                  id="factoryResetReason"
                  select
                  value={props.values.factoryResetReason}
                  className={classes.textField}
                  //style={{ visibility: props.values.factoryReset ? 'visible' : 'hidden' }}
                  variant="outlined"
                  required
                  onChange={handleChange('factoryResetReason')}
                >
                  {props.factoryResetReasons.map(item => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))
                  }
                </TextField>
                <TextField
                  id="NonFactoryResetNotes"
                  label="Reason detail"
                  required
                  helperText="Please provide further detail"
                  onChange={handleChange('NonFactoryResetNotes')}
                  value={props.values.NonFactoryResetNotes}
                  className={classes.textField}
                  //style={{ visibility: props.values.factoryReset ? 'visible' : 'hidden' }}
                  variant="outlined"
                />
              </>
              : null
            }        
            </FormGroup>
: null} */}
          </li>
        </ul>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox color="primary" checked={props.policy} disabled={props.values.bookingType === ''} onChange={handleCheckboxChange()} />
            }
            className="bodyText"
            label="Return Booking"
          />
        </FormGroup>
      </Grid>
      <Grid item xs={4}>
        <form className={classes.root} noValidate autoComplete="off">
        <div>
            <TextField
                label="Select Booking Type"
                id="bookingType"
                select
                value={props.values.bookingType}
                className={classes.textField}
                variant="outlined"
                placeholder="Please select"
                onChange={handleChange('bookingType')}
              >
                <MenuItem value="Book Phone">Book Phone</MenuItem>
                <MenuItem value="Book Accessory">Book Accessory</MenuItem>
            </TextField>
          </div>
          {props.stores.length !== 0 ?
            <>
              <TextField
                select
                label="Select Store"
                value={props.values.store}
                onChange={handleChange('store')}
                className={classes.textField}
                variant="outlined"
                id='store'
                helperText="Select the Store you are currently in."
              >
                {props.stores.map(item => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))
                }
              </TextField>
            </>
            :
            null
          }
          <div>
            <TextField
              label="Select Channel"
              id="channelSelect"
              select
              value={props.values.channel}
              className={classes.textField}
              disabled={props.values.bookingType === "Book Accessory"}
              variant="outlined"
              placeholder="Please select"
              onChange={handleChange('channel')}
            >
              <MenuItem value="PAYM Exchange Return">PAYM Exchange Return</MenuItem>
              <MenuItem value="PAYM Cancellation">PAYM Cancellation</MenuItem>
              <MenuItem value="PAYG Return">PAYG Return</MenuItem>
            </TextField>
          </div>
        </form>
      </Grid>
    </Grid>
  );
}