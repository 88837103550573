import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    TextField, MenuItem, Grid, Button, Chip, FormGroup, FormControlLabel, Checkbox, Divider
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Auth } from 'aws-amplify';

import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsAdapter from "@date-io/date-fns"

import Api from '../service/api';
import handleErrors from '../service/helpers/errorResponse';
import { config } from '../config/config';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3, 2),
        margin: theme.spacing(3, 3, 3, 3),
        height: '80vh',
    },
    container: {
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: 16,
        marginTop: 5,
        width: 300,
    },
    dense: {
        marginTop: 19,
    },
    menu: {
        width: 200,
    },
    button: {
        margin: theme.spacing(1),
        maxHeight: 50
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    formControl: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 300,
    },
}));

export default function BookUnit(props) {
    const classes = useStyles();

    const [models, setModels] = React.useState();
    const [manufactures, setManufactures] = React.useState();
    const [primaryFaults, setPrimaryFaults] = React.useState();
    const [secondaryFaults, setSecondaryFaults] = React.useState();
    const [mceReasons, setMceReasons] = React.useState([]);
    const [mceResetReasons, setMceResetReasons] = React.useState([]);
    const date = new Date();
    const regex = RegExp('bundle', 'gi')

    useEffect(() => {
        if (config.environment !== "local") {
            Api.getPrimaryFaultCodes(Auth.currentSession()).then((response) => {
                setPrimaryFaults(response.data)
            })
                .catch((error) => {
                    handleErrors(error)
                })

            Api.getManufactures(Auth.currentSession()).then((response) => {
                setManufactures(response.data)
            })
                .catch((error) => {
                    handleErrors(error)
                })

            Api.getMceReasons(Auth.currentSession()).then((response) => {
                setMceReasons(response.data)
                const resetReason = response.data.filter(item => !item.includes("MCE"))
                setMceResetReasons(resetReason)
            })
                .catch((error) => {
                    handleErrors(error)
                })
        }
        // eslint-disable-next-line    
    }, [])

    const handleChange = name => event => {
        props.onChange(name, event);
        if (name === 'primaryFaultCode') {
            getSecondary(event.target.value);
        }
        if (name === 'Manufacturer') {
            getModel(event.target.value);
        }
    };

    const [notesValid, setNotesValid] = React.useState({ error: false, message: "" })
    const notesValidation = name => event => {
        const value = event.target.value
        props.onChange(name, event)
        var lettersRegEx = /^[a-zA-Z\s]*$/
        var successiveLettersRegEx = /([a-zA-Z\s])\1\1(?=[a-zA-Z\s]){0}/g
        if (value.length <= 30 || !lettersRegEx.test(value)) {
            setNotesValid({ error: true, message: "Must not contain special characters, numbers or be less than 30 characters." })
        } else if (successiveLettersRegEx.test(value)) {
            setNotesValid({ error: true, message: 'No more than 2 successive characters.' })
        } else {
            setNotesValid({ error: false, message: '' })
            props.validNotesCheck(true)
        }
    }

    function getModel(model) {
        Api.getModel(Auth.currentSession(), model).then((response) => {
            setModels(response.data)
        })
            .catch((error) => {
                alert('Cannot retreive the list of Manufactures, contact an administrator')
            })
    }

    function handleDateChange(date) {
        props.date('SaleDate', date);
    }

    function getSecondary(secondary) {
        Api.getSecondaryFaultCodes(Auth.currentSession(), secondary).then((response) => {
            setSecondaryFaults(response.data)
        })
            .catch((error) => {
                handleErrors(error)
            })
    }

    const handleCheckboxChange = () => event => {
        props.onCheckboxChange(event);
    };

    return (
        <Grid container spacing={2} style={{ height: '100%', overflowY: 'auto' }}>
            <form className={classes.container} noValidate autoComplete="off">
                <Grid item xs={8}>
                    <TextField
                        id="IMEI"
                        label="IMEI:"
                        required
                        helperText="Enter the units IMEI number."
                        onChange={handleChange('IMEI')}
                        value={props.values.IMEI}
                        className={classes.textField}
                        margin="normal"
                        variant="outlined"
                    />
                    <Button
                        variant="contained"
                        className={classes.button}
                        color="primary"
                        disabled={props.values.IMEI === ''}
                        onClick={props.onSearchClick}
                    >
                        Search
                    </Button>
                    {/* {(props.values.TPNB.length === 0 && props.values.channel === 'PAYM Cancellation' && props.search) &&
                        <div>
                            {manufactures && manufactures.length !== 0 ?
                                <TextField
                                    select
                                    label="Select Manufacture"
                                    value={props.values.Manufacturer}
                                    className={classes.textField}
                                    variant="outlined"
                                    onChange={handleChange('Manufacturer')}
                                    name="manufacture"
                                    id="manufacture"
                                >
                                    {manufactures.map(item => (
                                        <MenuItem key={item} value={item}>
                                            {item}
                                        </MenuItem>
                                    ))
                                    }
                                </TextField>
                                :
                                null
                            }
                            {models && models.length !== 0 ?
                                <TextField
                                    select
                                    label="Select Model"
                                    value={props.values.Model}
                                    className={classes.textField}
                                    variant="outlined"
                                    onChange={handleChange('Model')}
                                    name="Model"
                                    inputProps={{
                                        name: 'Model',
                                        id: 'Model',
                                    }}
                                >
                                    {models.map(item => (
                                        <MenuItem key={item} value={item}>
                                            {item}
                                        </MenuItem>
                                    ))
                                    }
                                </TextField>
                                :
                                null
                            }
                        </div>
                    } */}
                </Grid>
                {props.phoneDetails.Description.length > 0 &&
                    <Grid item xs={4}>
                        <>
                            <Chip
                                key={props.phoneDetails.Description}
                                label={props.phoneDetails.Description}
                                className={classes.chip}
                            />
                        </>

                    </Grid>
                }
                {props.bundleItems.length > 0 &&
                    <Grid item xs={12}>
                        <>
                            {props.bundleItems.map((item, index) => {
                                return (
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            id={item.Description}
                                            control={
                                                <Checkbox id={item.Description} color="primary"
                                                    checked={props.bundleItems[index].Returned}
                                                    onChange={(event) => props.handleBundleItems('checkbox', event, index)}
                                                />
                                            }
                                            className="bodyText"
                                            label={item.Description}
                                        />
                                        {item.SerialRequired && props.bundleItems[index].Returned ?
                                            <TextField
                                                id={item.Description}
                                                label="Enter Serial Number:"
                                                required
                                                onChange={(event) => props.handleBundleItems('serial', event, index)}
                                                value={props.bundleItems[index].SerialNumber}
                                                className={classes.textField}
                                                margin="normal"
                                                variant="outlined"
                                            />
                                            : <></>
                                        }
                                    </Grid>
                                )
                            })}
                        </>

                    </Grid>
                }
                {
                    (props.phoneDetails.SmartPhone === 'Yes'&& props.phoneDetails.MCE === 'No') &&
                    <>
                    <br /><br />
                    <Grid item xs={12}>
                        <Divider />
                        <br /><br />
                        <Alert severity="warning">
                            <AlertTitle>Warning</AlertTitle>
                            Please note this device should be fully data cleared for GDPR compliance purposes.<br />

                            If the MCE process has been unsuccessful please try to manually clear the device before continuing the booking.<br />

                            This booking will be flagged to the Phone Shop manager for review.<br />
                        </Alert>
                        <br /><br />

                        {/* <TextField
        select
        label="Put device through MCE?"
        value={props.values.MceBooked}
        required
        style={{ marginTop: 15 }}
        onChange={handleChange('MceBooked')}
        className={classes.textField}
        variant="outlined"
        id='MceBooked'
    >
        <MenuItem key="yes" value="Yes">
            Yes
        </MenuItem>
        <MenuItem key="no" value="No">
            No
        </MenuItem>
    </TextField> */}
                    </Grid><Grid
                        container
                        direction="row"
                        justifyContent="space-evenly"
                        alignItems="center"
                    >
                            <Grid item xs={12}>
                                <TextField
                                    select
                                    label="Select non MCE reason"
                                    required
                                    value={props.values.MCEReason}
                                    onChange={handleChange('MCEReason')}
                                    className={classes.textField}
                                    variant="outlined"
                                    id='MCEReason'
                                >
                                    {mceReasons.map(item => (
                                        <MenuItem key={item} value={item}>
                                            {item}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <TextField
                                    id="notes"
                                    label="Notes"
                                    multiline
                                    required
                                    minRows="4"
                                    maxRows="10"
                                    value={props.values.MCENotes}
                                    onChange={notesValidation('MCENotes')}
                                    error={notesValid.error}
                                    helperText={notesValid.message === '' ?
                                        'Must not contain special characters, numbers or be less than 30 characters. No more than 2 successive characters.'
                                        : notesValid.message}
                                    className={classes.textField}
                                    variant="outlined" />
                                {props.values.MCEReason.includes('MCE') &&
                                    <Grid item xs={12}>

                                        <TextField
                                            id="ItTicketRef"
                                            label="IT ticket reference number"
                                            required
                                            helperText="Enter the 8 digit IT ticket reference number"
                                            onChange={handleChange('ItTicketRef')}
                                            value={props.values.ItTicketRef}
                                            className={classes.textField}
                                            margin="normal"
                                            variant="outlined" />
                                    </Grid>}
                            </Grid>
                            <Grid item xs={12}>

                                <TextField
                                    select
                                    label="Have you manually reset the unit?"
                                    value={props.values.MCEResetUnit}
                                    required
                                    style={{ marginTop: 15 }}
                                    onChange={handleChange('MCEResetUnit')}
                                    className={classes.textField}
                                    variant="outlined"
                                    id='MCEResetUnit'
                                >
                                    <MenuItem key="yes" value="Yes">
                                        Yes
                                    </MenuItem>
                                    <MenuItem key="no" value="No">
                                        No
                                    </MenuItem>
                                </TextField>
                                {props.values.MCEResetUnit === 'No' &&
                                    <TextField
                                        select
                                        label="Select reason."
                                        required
                                        style={{ marginTop: 15 }}
                                        value={props.values.MCEResetReason}
                                        onChange={handleChange('MCEResetReason')}
                                        className={classes.textField}
                                        variant="outlined"
                                        id='MCEResetReason'
                                    >
                                        {mceResetReasons.map(item => (
                                            <MenuItem key={item} value={item}>
                                                {item}
                                            </MenuItem>
                                        ))}
                                    </TextField>}
                            </Grid>
                            <Grid item xs={12}>

                                <TextField
                                    select
                                    label="How many times was MCE tried?"
                                    value={props.values.MCEtried}
                                    required
                                    onChange={handleChange('MCEtried')}
                                    className={classes.textField}
                                    variant="outlined"
                                    id='MCEtried'
                                >
                                    <MenuItem key="1" value="1">
                                        1
                                    </MenuItem>
                                    <MenuItem key="2" value="2">
                                        2
                                    </MenuItem>
                                    <MenuItem key="3" value="3+">
                                        3+
                                    </MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>

                                <TextField
                                    select
                                    label="Did you charge the unit if no power?"
                                    value={props.values.MCEUnitCharge}
                                    required
                                    onChange={handleChange('MCEUnitCharge')}
                                    className={classes.textField}
                                    variant="outlined"
                                    id='MCEUnitCharge'
                                >
                                    <MenuItem key="yes" value="Yes">
                                        Yes
                                    </MenuItem>
                                    <MenuItem key="no" value="No">
                                        No
                                    </MenuItem>
                                </TextField>
                                {props.values.MCEUnitCharge === "Yes" &&

                                    <TextField
                                        select
                                        label="Was it for more than 2 minutes?"
                                        required
                                        value={props.values.MCETwomins}
                                        onChange={handleChange('MCETwomins')}
                                        className={classes.textField}
                                        variant="outlined"
                                        id='MCETwomins'
                                    >
                                        <MenuItem key="yes" value="Yes">
                                            Yes
                                        </MenuItem>
                                        <MenuItem key="no" value="No">
                                            No
                                        </MenuItem>
                                    </TextField>}
                                <br /> <br /><Divider />
                            </Grid>
                        </Grid>
                        </>
                }
                <Grid item xs={12}>
                    {
                        props.lockedResponse === 'Yes' &&
                        <Alert severity="error">
                            <AlertTitle>Error</AlertTitle>
                            We have detected that find my iphone is turned on. It must be turned off before you can proceed. Please go to device help if you require any support on ‘Find my iphone’ <a href="https://tmservice.my.salesforce.com/">https://tmservice.my.salesforce.com/</a>
                        </Alert>
                    }
                    {
                        props.lockedResponse === 'Unknown' &&
                        <Alert severity="warning">
                            <AlertTitle>Warning</AlertTitle>
                            Unable to automatically check lock status of the device, please ensure phone is not locked before completing the booking.
                        </Alert>
                    }
                    {
                        (props.search && props.values.imeiError !== '') &&
                        <div>
                            <Alert severity="error">
                                <AlertTitle>Error</AlertTitle>
                                {props.values.imeiError}
                            </Alert>
                        </div>
                    }
                </Grid>
                <br />
                <Grid item xs={12}>
                    {props.phoneDetails.Description !== '' && props.phoneDetails.Description !== undefined &&
                        regex.test(props.phoneDetails.Description) ?
                        <>
                            <FormGroup row style={{ paddingLeft: "12px" }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={props.bundle} onChange={handleCheckboxChange()} />
                                    }
                                    label="Bundle item recieved?"
                                />
                            </FormGroup>
                        </> : null
                    }
                    {props.bundle &&
                        <>
                            <TextField
                                id="BundleSerial"
                                label="Bundle item Serial Number:"
                                required
                                helperText="Enter the Bundle item Serial Number"
                                onChange={handleChange('BundleSerial')}
                                value={props.values.BundleSerial}
                                className={classes.textField}
                                margin="normal"
                                variant="outlined"
                            /></>}
                </Grid>
                <br />
                <Grid item xs={12}>
                    {!props.values.channel.includes('PAYG') &&
                        <TextField
                            id="OrderNumber"
                            label="Order Number:"
                            required
                            helperText="Enter the Order Number"
                            onChange={handleChange('OrderNumber')}
                            value={props.values.OrderNumber}
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                        />
                    }
                    <TextField
                        id="ToteNum"
                        label="Seal 1:"
                        required
                        helperText="Enter Seal 1 Number"
                        onChange={handleChange('ToteNum')}
                        value={props.values.ToteNum}
                        className={classes.textField}
                        margin="normal"
                        variant="outlined"
                    />
                    <TextField
                        id="other tote field"
                        label="Seal 2:"
                        required
                        helperText="Enter Seal 2 Field"
                        onChange={handleChange('OtherTote')}
                        value={props.values.OtherTote}
                        className={classes.textField}
                        margin="normal"
                        variant="outlined"
                    />
                    <MuiPickersUtilsProvider utils={DateFnsAdapter}>
                        <KeyboardDatePicker
                            autoOk
                            required
                            variant="inline"
                            inputVariant="outlined"
                            label="Sale date"
                            disableFuture
                            minDate={date.setDate(date.getDate() - 30)}
                            format="dd/MM/yyyy"
                            maxDateMessage={'Sale date can not be in the future'}
                            minDateMessage={'Sale date can not be more than 30 days ago'}
                            onError={(error, value) => error ? props.validDate(false) : props.validDate(true)}
                            className={classes.textField}
                            value={props.values.SaleDate}
                            onChange={date => handleDateChange(date)}
                        />
                    </MuiPickersUtilsProvider>
                    {primaryFaults && primaryFaults.length !== 0 ?
                        <TextField
                            select
                            label="Primary Fault Code"
                            required
                            variant="outlined"
                            value={props.values.primaryFaultCode}
                            className={classes.textField}
                            onChange={handleChange('primaryFaultCode')}
                            name="PrimaryFaultCode"
                            inputProps={{
                                name: 'PrimaryFaultCode',
                                id: 'PrimaryFaultCode',
                            }}
                        >
                            {primaryFaults.map(item => (
                                <MenuItem key={item.Primary} value={item.Primary}>
                                    {item.Primary}
                                </MenuItem>
                            ))
                            }
                        </TextField>
                        :
                        null
                    }
                    {secondaryFaults && secondaryFaults.length !== 0 ?
                        <TextField
                            select
                            required
                            label="Secondary Fault Code"
                            variant="outlined"
                            value={props.values.SecondaryFaultCode}
                            className={classes.textField}
                            onChange={handleChange('SecondaryFaultCode')}
                            name="SecondaryFaultCode"
                            inputProps={{
                                name: 'SecondaryFaultCode',
                                id: 'SecondaryFaultCode',
                            }}
                        >
                            {secondaryFaults.map(item => (
                                <MenuItem key={item.Secondary} value={item.Secondary}>
                                    {item.Secondary}
                                </MenuItem>
                            ))
                            }
                        </TextField>
                        :
                        null
                    }
                    <TextField
                        id="freetext"
                        label="Additional information"
                        multiline
                        minRows="4"
                        maxRows="10"
                        value={props.values.FreeText}
                        onChange={handleChange('FreeText')}
                        className={classes.textField}
                        variant="outlined"
                    />
                </Grid>
            </form>
        </Grid>
    )
}